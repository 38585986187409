import { Component } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import { BLANK_IMAGE_PLACEHOLDER } from 'constants/appConstants';
import { evNavigationClick, evTopLevelNavigationClick } from 'events/headerFooter';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { makeOpenLiveChat } from 'helpers/ClientUtils';
import { track } from 'apis/amethyst';
import { stripSpecialCharsDashReplace as strip } from 'helpers';
import Link from 'components/hf/HFLink';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import marketplace from 'cfg/marketplace.json';

const { chatUrl, chatWindowName, phoneNumber, phoneNumberTel } = marketplace;

import css from 'styles/components/hf/6pm/headerNav.scss';

const openLiveChat = makeOpenLiveChat(chatWindowName);

export const SubNav = ({ index, parent, headerMenues, hasBeenHovered }, { testId }) => {
  const i = index + 1;
  const { slotData, slotNames } = headerMenues[`headermenu${i}`];

  const handleClick = (evt, text) => {
    trackEvent('TE_HEADERFOOTER_MAIN_NAV', evt);
    trackLegacyEvent('Main-Nav', strip(parent), strip(evt));
    track(() => [
      evNavigationClick,
      {
        valueClicked: text,
        parentDropdown: parent
      }
    ]);
  };

  if (slotNames?.length) {
    const navImg = slotData[`navigation-${i}`];
    return (
      <div data-test-id={testId('subNav')} className={css.headerDropdown}>
        <div className={css.headerSubNav}>
          <div className={css.lists}>
            {slotNames.map(v => {
              const item = slotData[v];
              if (item.componentName === 'subNavMenu') {
                const { text, link, type, gae } = item.heading;
                const hasHeading = !!text;
                const hasHeadingLink = !!link;
                return (
                  <div key={v} className={cn({ [css.brandindex]: type === 'brandindex' })}>
                    {hasHeading && hasHeadingLink ? (
                      <Link
                        data-test-id={testId(`${parent}-${text}`)}
                        className={css.navMenuHeading}
                        to={link}
                        onClick={() => handleClick(gae || text, text)}
                      >
                        {text}
                      </Link>
                    ) : (
                      hasHeading && <p className={css.navMenuHeading}>{text}</p>
                    )}
                    {item.subNavMenu && (
                      <ul>
                        {item.subNavMenu.map(({ link, gae, text }) => (
                          <li key={link}>
                            <Link
                              to={link}
                              data-test-id={testId(`${parent}-${hasHeading ? item.heading.text : ''}-${text}`)}
                              className={cn({
                                [css.navMenuHeading]: !hasHeading
                              })}
                              onClick={() => handleClick(`${hasHeading ? `${item.heading.text}-` : ''}${gae || text}`, text)}
                            >
                              {text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              }
            })}
          </div>
          {navImg && (
            <div className={css.listImage}>
              {navImg.images.map(({ gae, text, alt, src, href, title }) => (
                <Link
                  key={href}
                  to={href}
                  title={title}
                  data-test-id={testId(`${parent}-${text || alt || title || gae}`)}
                  onClick={() => {
                    const evt = gae || alt || text;
                    trackEvent('TE_HEADERFOOTER_NAV_CLICKME', evt);
                    trackLegacyEvent('Global-Header-sixpmrefreshheader', 'Promos', strip(evt));
                    track(() => [
                      evNavigationClick,
                      {
                        valueClicked: alt,
                        parentDropdown: parent
                      }
                    ]);
                  }}
                >
                  <img src={hasBeenHovered ? src : BLANK_IMAGE_PLACEHOLDER} alt={alt} />
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

SubNav.contextTypes = {
  testId: PropTypes.func
};

export class HeaderNav extends Component {
  static contextTypes = {
    testId: PropTypes.func
  };

  state = {
    indexesHovered: []
  };

  handleHover = i => {
    const { indexesHovered } = this.state;
    if (!indexesHovered.includes(i)) {
      this.setState({ indexesHovered: [...indexesHovered, i] });
    }
  };

  render() {
    const { indexesHovered } = this.state;
    const { navMenu, headerMenues, phoneCallout, forceHideSubNav, enableLiveChat } = this.props;
    const { testId } = this.context;
    return (
      <div className={css.headerNav} data-sub-nav data-test-id={testId('headerNav')}>
        <div className={css.mobileNavClose}>
          <button
            type="button"
            data-nav-toggle
            data-test-id={testId('headerNavMobileClose')}
            onClick={() => {
              trackLegacyEvent('Global', 'Header', 'Mobile-Nav-Close');
            }}
          >
            Close
          </button>
        </div>
        <div className={css.phoneChatWrapper}>
          <div className={css.mobilePhone}>
            <a
              href={phoneNumberTel}
              data-test-id={testId('headerNavCallCustomerService')}
              onClick={() => {
                trackLegacyEvent('Global', 'Header', 'Phone-Mobile');
                trackEvent('TE_HEADERFOOTER_MAIN_NAV', 'Phone Mobile');
                track(() => [
                  evTopLevelNavigationClick,
                  {
                    valueClicked: 'Need Help'
                  }
                ]);
              }}
              className={css.iconPhone}
            >
              Call customer service
            </a>
            <div>
              <p className={css.phone} data-test-id={testId('headerPhoneMobile')}>
                {phoneNumber}
              </p>
              {phoneCallout}
            </div>
          </div>
          {enableLiveChat && (
            <a
              className={css.mobileChat}
              href={chatUrl}
              data-test-id={testId('headerLiveChatMobile')}
              onClick={e => {
                openLiveChat(e);
                trackLegacyEvent('Global', 'Header', 'Chat-Mobile');
                trackEvent('TE_HEADERFOOTER_MAIN_NAV', 'Live Chat Mobile');
                track(() => [
                  evTopLevelNavigationClick,
                  {
                    valueClicked: 'Live Chat'
                  }
                ]);
              }}
            >
              <p className={css.iconChat}>Live Chat</p>
              <div>
                <p className={css.chat} data-test-id={testId('headerChatMobile')}>
                  Live Chat
                </p>
              </div>
            </a>
          )}
        </div>
        <ul>
          {navMenu.map(({ text, link, nochildren }, i) => (
            <li
              onFocus={() => this.handleHover(i)}
              onMouseOver={() => this.handleHover(i)}
              key={link}
              className={cn({ [css.noMenu]: nochildren || forceHideSubNav })}
            >
              <Link
                to={link}
                data-test-id={testId(`headerNav${text}`)}
                onClick={() => {
                  trackLegacyEvent('Main-Nav', 'Heading', strip(text));
                  trackEvent('TE_HEADERFOOTER_MAIN_NAV', text);
                  track(() => [
                    evTopLevelNavigationClick,
                    {
                      valueClicked: text
                    }
                  ]);
                }}
              >
                {text}
              </Link>
              {!nochildren && <SubNav hasBeenHovered={indexesHovered.includes(i)} index={i} parent={text} headerMenues={headerMenues} />}
            </li>
          ))}
          <li className={css.mobileHelp}>
            <Link
              to="/c/customer-service-center"
              data-test-id={testId('headerNavNeedHelp')}
              onClick={() => {
                trackLegacyEvent('Global', 'Header', 'Help-Mobile');
                trackEvent('TE_HEADERFOOTER_MAIN_NAV', 'Help Mobile');
                track(() => [
                  evTopLevelNavigationClick,
                  {
                    valueClicked: 'Need Help'
                  }
                ]);
              }}
            >
              Need Help?
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default withErrorBoundary('HeaderNav', HeaderNav);
