import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { stripSpecialCharsDashReplace as strip } from 'helpers';
import Link from 'components/hf/HFLink';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/hf/6pm/banner.scss';

export const Banner = ({ data, hideMobile, type }, { testId }) => {
  if (!data || data.componentName !== 'banner') {
    return null;
  }

  const { link, gae, alt, image, retina, texts, linktext, bgcolor } = data;

  const getContainerClass = () => {
    switch (type) {
      case 'headerTopBanner':
        return css.headerTopBanner;
      case 'headerTopCallout':
        return css.headerTopCallout;
      case 'searchBanner':
        return css.searchBanner;
      default:
        return null;
    }
  };

  return (
    <div data-header-additional className={cn(getContainerClass(), { [css.hideMobile]: hideMobile })}>
      <Link
        to={link}
        className={cn(css.bannerComponent, {
          [css.bgcolorRed]: bgcolor === 'red'
        })}
        onClick={() => {
          const evt = gae || alt;
          trackLegacyEvent('Global-Header-sixpmrefreshheader', 'Promos', strip(evt));
          trackEvent('TE_HEADER_PROMOS', `${type} ${evt}`);
        }}
        data-test-id={testId(type)}
      >
        {image && <img src={image} alt={alt} srcSet={retina ? `${image} 1x, ${retina} 2x` : null} />}
        {texts && (
          <p>
            {texts.map(({ type, copy }) => (
              <span className={cn({ [css.red]: type === 'red' })} key={copy}>
                {`${copy} `}
              </span>
            ))}
          </p>
        )}
        {linktext && <span className={css.calloutlink}>{linktext}</span>}
      </Link>
    </div>
  );
};

Banner.contextTypes = {
  testId: PropTypes.func
};

export default withErrorBoundary('Banner', Banner);
