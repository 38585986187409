import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { evTopLevelNavigationClick } from 'events/headerFooter';
import { saveExplicitSearchEventToLocalStorage } from 'helpers/searchInputUtils';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import Link from 'components/hf/HFLink';
import SkipLinks from 'components/common/SkipLinks';
import { CHECKOUT_URL_RE } from 'common/regex';
import { pluralize, stripSpecialCharsDashReplace as strip } from 'helpers/';
import { makeOpenLiveChat } from 'helpers/ClientUtils';
import { getSearchInputValue } from 'helpers/HFHelpers';
import { onEvent } from 'helpers/EventHelpers';
import { isMobileContentBreakpoint } from 'helpers/HtmlHelpers';
import { termEncoder } from 'helpers/SearchUtils';
import { showCartModal } from 'actions/cart';
import {
  getCartCount,
  handleHFSearchChange,
  handleSearchKeyUp,
  searchByTerm,
  setHFSearchSuggestionsActiveIndex,
  setHFSearchTerm
} from 'actions/headerfooter';
import LandingPageImage from 'components/landing/LandingPageImage';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import SearchSuggestions from 'components/hf/SearchSuggestions';
import Banner from 'components/hf/6pm/Banner';
import HeaderNav from 'components/hf/6pm/HeaderNav';
import Ad from 'components/landing/Ad';
import marketplace from 'cfg/marketplace.json';
import EmergencyBanner from 'components/EmergencyBanner';

import css from 'styles/containers/hf/6pm/header.scss';

const {
  homepage,
  phoneNumber,
  phoneNumberTel,
  chatUrl,
  chatWindowName,
  cart: { cartName }
} = marketplace;

const openLiveChat = makeOpenLiveChat(chatWindowName);

const NAV_OPEN_CLASS = 'navOpen';

export class Header extends Component {
  static displayName = 'Header';

  state = {
    accountExpanded: false,
    forceHideSubNav: true,
    showMiniHeader: false
  };

  componentDidMount() {
    const { getCartCount } = this.props;

    getCartCount();

    // Handle clicks on the body to close any open dropdown
    onEvent(window, 'click', this.handleDocClick, null, this);

    // Ensure nav isn't being openned on load
    this.handleNavHide();

    // See if we need the small version of the header
    this.checkForSmallerHeader();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPathname }
    } = prevProps;
    const {
      location: { pathname },
      cartCount,
      getCartCount
    } = this.props;
    const hasLocationChanged = prevPathname !== pathname;

    if (hasLocationChanged) {
      // Close navs to prevent persisting overlaying during client-routing
      this.handleNavHide();
      document.body.classList.remove(NAV_OPEN_CLASS);

      this.checkForSmallerHeader();
    }

    if (hasLocationChanged && typeof cartCount !== 'number') {
      getCartCount();
    }
  }

  handleDocClick = e => {
    this.handleDocClickForNav(e);
    this.handleDocClickForAccountMobileDropdown(e);
  };

  handleDocClickForNav = e => {
    const inNav = e.target.closest('[data-sub-nav]');
    const isOpen = document.body.classList.contains(NAV_OPEN_CLASS);

    if (e.target.closest('[data-nav-toggle]')) {
      document.body.classList.toggle(NAV_OPEN_CLASS);
    } else if (!inNav && isOpen) {
      document.body.classList.remove(NAV_OPEN_CLASS);
    }
  };

  handleNavHide = () => {
    // Clear focus to prevent automatically reopenning nav after timeout
    if (document.activeElement?.closest('header')) {
      document.activeElement.blur();
    }
    // Temporarily hide navmenu in order to keep it from remaining open during client routing
    this.setState({ forceHideSubNav: true });
    setTimeout(() => {
      this.setState({ forceHideSubNav: false });
    }, 600);
  };

  handleDocClickForAccountMobileDropdown = e => {
    const isMobile = isMobileContentBreakpoint(document.body);
    const { accountExpanded } = this.state;
    if (isMobile && !e.target.dataset.headerAccountToggle && accountExpanded) {
      this.setState({ accountExpanded: false });
    }
  };

  handleAccountToggleClick = e => {
    trackLegacyEvent('Global', 'Header', 'My-Account');
    trackEvent('TE_HEADER_ACCOUNT_DROPDOWN');

    if (isMobileContentBreakpoint(document.body)) {
      e.preventDefault();
      const { accountExpanded } = this.state;
      this.setState({ accountExpanded: !accountExpanded });
    }
  };

  checkForSmallerHeader = () => {
    const {
      location: { pathname }
    } = this.props;
    const showMiniHeader = !!pathname.match(CHECKOUT_URL_RE);
    this.setState({ showMiniHeader });
  };

  makeSkipLink = () => {
    const links = [{ id: 'main', value: 'Skip to main content' }];
    return <SkipLinks links={links} />;
  };

  makeLogo = () => {
    const { testId } = this.context;
    const { content } = this.props;
    const data = content?.Header?.slotData?.['logo']?.images?.[0];
    if (data) {
      const { href, gae, alt, src, retina, height, width } = data;
      const imageProps = { src, retina, width, height, alt };
      return (
        <Link
          to={href || '/'}
          className={css.logo}
          onClick={() => {
            const evt = gae || alt;
            trackLegacyEvent('Global-Header-sixpmrefreshheader', 'Promos', strip(evt));
            trackEvent('TE_HEADER_LOGO', evt);
            track(() => [
              evTopLevelNavigationClick,
              {
                valueClicked: 'Header Logo'
              }
            ]);
          }}
          data-test-id={testId('headerLogo')}
        >
          <LandingPageImage {...imageProps} />
        </Link>
      );
    }
    return null;
  };

  makePhoneCallout = () => {
    const { content } = this.props;
    const data = content?.Header?.slotData?.clthours;
    return data && <div className={css.time}>{data.pageContent.body}</div>;
  };

  makeMyAccountDropdown = () => {
    const { testId } = this.context;
    const {
      holmes: { firstName }
    } = this.props;
    return (
      <div className={css.iconDropdown}>
        <Link
          data-test-id={testId('headerAccountToggle')}
          data-header-account-toggle
          aria-expanded={this.state.accountExpanded}
          to="/account"
          className={css.iconAccount}
          onClick={this.handleAccountToggleClick}
        >
          My Account
        </Link>
        <ul>
          {firstName ? (
            <>
              <li className={css.name}>Hello, {decodeURIComponent(firstName)}</li>
              <li>
                <Link
                  data-test-id={testId('headerAccountLogout')}
                  to="/logout"
                  onClick={() => {
                    trackLegacyEvent('Global', 'Header', 'Logout');
                    trackEvent('TE_HEADER_ACCOUNT_SIGNOUT');
                  }}
                >
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link
                to="/login"
                data-test-id={testId('headerAccountLogin')}
                onClick={() => {
                  trackLegacyEvent('Global', 'Header', 'Log-In-or-Register');
                  trackEvent('TE_HEADER_ACCOUNT_SIGNIN');
                }}
              >
                Log In or Register
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/account"
              data-test-id={testId('headerAccountMyAccount')}
              onClick={() => {
                trackLegacyEvent('Global', 'Header', 'My-Account-Dropdown');
                trackEvent('TE_HEADER_ACCOUNT_MYACCOUNT');
              }}
            >
              My Account
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  makeBotImageBanner = () => {
    const { testId } = this.context;
    const { content } = this.props;
    const data = content?.Header?.slotData?.['bottom-banner']?.images?.[0];
    if (data) {
      const { href, gae, alt, src, retina, width, height } = data;
      const imageProps = { src, retina, width, height, alt };
      return (
        <Link
          data-header-additional
          className={cn(css.navImageBanner, css.headerContainer)}
          to={href || '/'}
          data-test-id={testId('headerBottomBanner')}
          onClick={() => {
            trackLegacyEvent('Global-Header-sixpmrefreshheader', 'Promos', strip(gae || alt));
            trackEvent('TE_HEADER_PROMOS', `Bottom Banner ${gae || alt}`);
          }}
        >
          <LandingPageImage {...imageProps} />
        </Link>
      );
    }
    return null;
  };

  handleSearchSubmit = e => {
    const {
      props: { searchByTerm, suggestionIndex, suggestions, setHFSearchTerm },
      context: { router }
    } = this;
    const { target } = e;
    e.preventDefault();
    const term = target['term'].value;
    const inputValueWithSuggestions = getSearchInputValue(term, suggestionIndex, suggestions);

    if (typeof suggestionIndex === 'number' && suggestions?.[suggestionIndex]) {
      // Search suggestion submit
      const item = suggestions[suggestionIndex];
      const searchObj = { term: item.suggestion };
      if (item.categories?.length) {
        // For when we're using searchsuggest with a category
        searchObj.facet = `zc2/${termEncoder(item.categories[0])}`;
      }
      trackEvent('TE_HEADER_SEARCH_SUGGESTION', inputValueWithSuggestions);
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', inputValueWithSuggestions);
      setHFSearchTerm(inputValueWithSuggestions);
      const autosuggestionClicked = true;
      const autosuggestionShown = true;
      saveExplicitSearchEventToLocalStorage(searchObj.term, autosuggestionShown, autosuggestionClicked);
      searchByTerm(searchObj, router);
    } else {
      // Normal header search
      trackEvent('TE_HEADER_SEARCHBAR_SUBMIT', term);
      const autosuggestionClicked = false;
      const autosuggestionShown = !!suggestions?.length;
      saveExplicitSearchEventToLocalStorage(term, autosuggestionShown, autosuggestionClicked);
      searchByTerm({ term }, router);
    }
  };

  handleCartClick = e => {
    const { showCartModal, isRemote } = this.props;
    const canShowCartModal = window.location.pathname !== '/cart'; // suppress cart modal on /cart

    if (canShowCartModal && !isRemote) {
      e.preventDefault();
      showCartModal(true);
    }

    trackEvent('TE_HEADER_CART');
    trackLegacyEvent('Global', 'Header', 'Cart');
    track(() => [
      evTopLevelNavigationClick,
      {
        valueClicked: 'Header Cart'
      }
    ]);
  };

  render() {
    const { testId } = this.context;
    const {
      content,
      cartCount,
      handleSearchKeyUp,
      handleHFSearchChange,
      suggestions,
      suggestionIndex,
      term,
      ad,
      enableLiveChat,
      location: { pathname },
      emergencyBanner
    } = this.props;
    const { forceHideSubNav, showMiniHeader } = this.state;
    const hasSuggestions = !!(suggestions && suggestions.length);
    const isHome = pathname === '/' || pathname.includes(homepage);
    const {
      'top-banner': topBannerData,
      'shipping-callout': topCalloutData,
      'search-banner': searchBannerData,
      navmenu
    } = content?.Header?.slotData || {};

    return (
      <div>
        {this.makeSkipLink()}
        {content?.fallback && <p className={css.hidden}>***Using Fallback Header***</p>}
        <Banner data={topBannerData} type="headerTopBanner" />
        <header data-header-container className={cn(css.header, { [css.miniHeader]: showMiniHeader })}>
          <div className={cn(css.headerTop, css.headerContainer)}>
            {this.makeLogo()}
            {!showMiniHeader && <Banner data={topCalloutData} hideMobile={!!topBannerData} type="headerTopCallout" />}
            <div className={css.phoneHelp}>
              <div className={css.phoneCallout}>
                <div>
                  <a
                    className={css.phone}
                    data-test-id={testId('headerPhoneDesktop')}
                    href={phoneNumberTel}
                    onClick={() => {
                      trackLegacyEvent('Global', 'Header', 'Phone-Desktop');
                      trackEvent('TE_HEADER_CUSTOMER_SERVICE', 'Phone Tel Desktop');
                    }}
                  >
                    {phoneNumber}
                  </a>
                  {this.makePhoneCallout()}
                </div>
              </div>
              {enableLiveChat && (
                <a
                  className={css.liveChatCallout}
                  href={chatUrl}
                  data-test-id={testId('headerLiveChatDesktop')}
                  onClick={e => {
                    openLiveChat(e);
                    trackLegacyEvent('Global', 'Header', 'Chat-Desktop');
                    trackEvent('TE_HEADER_CUSTOMER_SERVICE', 'Live Chat Desktop');
                  }}
                >
                  <p className={css.liveChatLink}>Live Chat</p>
                </a>
              )}
            </div>
            <div className={css.icons}>
              {this.makeMyAccountDropdown()}
              <div className={css.hideMobile}>
                <Link
                  data-test-id={testId('headerCustomerService')}
                  to="/c/customer-service-center"
                  className={css.iconHelp}
                  onClick={() => {
                    trackLegacyEvent('Global', 'Header', 'Help');
                    trackEvent('TE_HEADER_CUSTOMER_SERVICE', 'Customer Service');
                  }}
                >
                  Customer service
                </Link>
              </div>
              <div>
                <Link
                  to="/account/favorites"
                  data-test-id={testId('headerFavorites')}
                  className={css.iconHeart}
                  onClick={() => {
                    trackLegacyEvent('Global', 'Header', 'Favorites');
                    trackEvent('TE_HEADER_ACCOUNT_FAVORITES');
                  }}
                >
                  Favorites
                </Link>
              </div>
              <div>
                <Link data-test-id={testId('headerCart')} to="/cart" className={css.iconBag} onClick={this.handleCartClick}>
                  {cartCount > 0 ? (
                    <>
                      <span className={css.cartCount}>{cartCount}</span>
                      <span className={'sr-only'}>
                        {' '}
                        {pluralize('item', cartCount)} in {cartName}
                      </span>
                    </>
                  ) : (
                    <span className={'sr-only'}>{cartName} empty</span>
                  )}
                </Link>
              </div>
              <button
                data-test-id={testId('headerHamberderMenu')}
                type="button"
                className={css.mobileNavToggle}
                data-nav-toggle
                onClick={() => {
                  trackLegacyEvent('Global', 'Header', 'Mobile-Nav-Open');
                  trackEvent('TE_HEADERFOOTER_MAIN_NAV_TOGGLE');
                }}
              >
                Open Nav Menu
              </button>
            </div>
          </div>

          <HeaderNav
            enableLiveChat={enableLiveChat}
            navMenu={navmenu.navMenu}
            headerMenues={content?.HeaderMenues}
            phoneCallout={this.makePhoneCallout()}
            forceHideSubNav={forceHideSubNav}
          />

          <div className={css.searchSection}>
            <div className={css.searchDropdown}>
              <div className={cn(css.headerContainer, css.searchContainer)}>
                <form
                  onSubmit={this.handleSearchSubmit}
                  id="searchForm"
                  action="/search"
                  method="GET"
                  data-test-id={testId('headerSearchForm')}
                  className={css.navSearchForm}
                  role="search"
                >
                  <label htmlFor="searchAll" className={'sr-only'}>
                    Search 6pm.com
                  </label>
                  <input // eslint-disable-line jsx-a11y/no-access-key
                    id="searchAll"
                    type="search"
                    name="term"
                    role="combobox"
                    aria-controls={hasSuggestions ? 'hfSearchSuggest' : null}
                    aria-owns={hasSuggestions ? 'hfSearchSuggest' : null}
                    aria-autocomplete="list"
                    aria-haspopup="listbox"
                    aria-expanded={hasSuggestions}
                    onKeyUp={handleSearchKeyUp}
                    onChange={handleHFSearchChange}
                    className={css.searchInput}
                    placeholder="Search 6pm.com"
                    accessKey="s"
                    data-test-id={testId('headerSearchInput')}
                    value={getSearchInputValue(term, suggestionIndex, suggestions)}
                    autoComplete="off"
                  />
                  <button type="submit" data-test-id={testId('headerSearchSubmit')} className={css.searchSubmit}>
                    Submit Search
                  </button>
                  <SearchSuggestions
                    termSearched={getSearchInputValue(term, suggestionIndex, suggestions)}
                    suggestions={suggestions}
                    suggestionIndex={suggestionIndex}
                    handleZeroStateClick={null}
                  />
                </form>
                <Banner data={searchBannerData} hideMobile={!isHome} type="searchBanner" />
              </div>
            </div>
          </div>
          {emergencyBanner && <EmergencyBanner />}
        </header>
        {isHome && this.makeBotImageBanner()}
        <Ad slotDetails={ad} />
      </div>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object, // Not passed when we're a remote HF
  testId: PropTypes.func
};

function mapStateToProps(state) {
  const { content } = state.headerFooter;
  const { data: emergencyBanner } = state.emergencyBanner;

  return {
    enableLiveChat: state.killswitch.enableLiveChat,
    isRemote: state.headerFooter.isRemote,
    holmes: state.holmes,
    content: content,
    term: state.headerFooter.term,
    suggestions: state.headerFooter.suggestions,
    suggestionIndex: state.headerFooter.suggestionIndex,
    cartCount: state.cart.cartCount,
    location: state.router.location,
    ad: content.Header.slotData.ad,
    emergencyBanner
  };
}

const HeaderConnected = connect(mapStateToProps, {
  searchByTerm,
  getCartCount,
  handleHFSearchChange,
  setHFSearchTerm,
  setHFSearchSuggestionsActiveIndex,
  showCartModal,
  handleSearchKeyUp
})(Header);

const HeaderConnectedWithErrorBoundary = withErrorBoundary('Header', HeaderConnected);
export default HeaderConnectedWithErrorBoundary;
