import { emailHasher } from 'actions/account/account';
import { AD_URL, APS_URL, GPT_URL } from 'constants/appConstants';
import { injectLinkToHead, injectScriptToHead } from 'helpers/HtmlHelpers';
import { isDesktop } from 'helpers/ClientUtils';
import { logError } from 'middleware/logger';
import marketplace from 'cfg/marketplace.json';

export const PDP_NARROW_MID = 'PDP-NARROW-MID';
export const PDP_NARROW_TOP = 'PDP-NARROW-TOP';
export const SRP_NARROW_MID = 'SRP-NARROW-MID';
export const SRP_NARROW_TOP = 'SRP-NARROW-TOP';
export const PDP_NARROW_BOTTOM = 'PDP-NARROW-BOTTOM';
export const PDP_NARROW_BOTTOM_TOP = 'PDP-NARROW-BOTTOM-TOP';
export const SRP_NARROW_BOTTOM = 'SRP-NARROW-BOTTOM';
export const SRP_NARROW_BOTTOM_TOP = 'SRP-NARROW-BOTTOM-TOP';
export const PDP_WIDE_BOTTOM = 'PDP-WIDE-BOTTOM';
export const PDP_WIDE_BOTTOM_LEFT = 'PDP-WIDE-BOTTOM-LEFT';
export const PDP_WIDE_BOTTOM_RIGHT = 'PDP-WIDE-BOTTOM-RIGHT';
export const PDP_WIDE_TOP = 'PDP-WIDE-TOP';
export const SRP_WIDE_BOTTOM = 'SRP-WIDE-BOTTOM';
export const SRP_WIDE_BOTTOM_LEFT = 'SRP-WIDE-BOTTOM-LEFT';
export const SRP_WIDE_BOTTOM_RIGHT = 'SRP-WIDE-BOTTOM-RIGHT';
export const SRP_WIDE_TOP = 'SRP-WIDE-TOP';
export const PDP_WIDE_MID = 'PDP-WIDE-MID';
export const SRP_WIDE_MID = 'SRP-WIDE-MID';
export const HOME_NARROW_MID = 'HOME-NARROW-MID';
export const HOME_NARROW_BOTTOM = 'HOME-NARROW-BOTTOM';
export const HOME_WIDE_MID = 'HOME-WIDE-MID';
export const HOME_WIDE_BOTTOM = 'HOME-WIDE-BOTTOM';
export const LAND_NARROW_BOTTOM = 'LAND-NARROW-BOTTOM';
export const LAND_NARROW_TOP = 'LAND-NARROW-TOP';
export const LAND_WIDE_TOP = 'LAND-WIDE-TOP';
export const LAND_WIDE_BOTTOM = 'LAND-WIDE-BOTTOM';
export const PDP_STICKY_BOTTOM = 'PDP-STICKY-BOTTOM';
export const SRP_STICKY_BOTTOM = 'SRP-STICKY-BOTTOM';
export const TEST = 'test';

type Dimensions = [number, number];

// method for getting dimensions based on slot
export const getAdDimensions = (slot: string): Dimensions | null => {
  switch (slot) {
    case PDP_WIDE_BOTTOM:
    case PDP_WIDE_TOP:
    case SRP_WIDE_BOTTOM:
    case SRP_WIDE_TOP:
    case HOME_WIDE_MID:
    case HOME_WIDE_BOTTOM:
    case LAND_WIDE_BOTTOM:
    case LAND_WIDE_TOP:
    case TEST:
      return [728, 90];
    case PDP_NARROW_MID:
    case PDP_NARROW_TOP:
    case SRP_NARROW_MID:
    case SRP_NARROW_TOP:
    case HOME_NARROW_MID:
    case LAND_NARROW_BOTTOM:
    case LAND_NARROW_TOP:
    case HOME_NARROW_BOTTOM:
    case SRP_NARROW_BOTTOM_TOP:
    case PDP_NARROW_BOTTOM_TOP:
    case PDP_STICKY_BOTTOM:
    case SRP_STICKY_BOTTOM:
      return [320, 50];
    case PDP_NARROW_BOTTOM:
    case PDP_WIDE_MID:
    case SRP_NARROW_BOTTOM:
    case SRP_WIDE_BOTTOM_LEFT:
    case SRP_WIDE_BOTTOM_RIGHT:
    case PDP_WIDE_BOTTOM_LEFT:
    case PDP_WIDE_BOTTOM_RIGHT:
      return [300, 250];
    case SRP_WIDE_MID:
      return [160, 600];
    default:
      logError(`No dimensions named ${slot}.`);
      return null;
  }
};

// map for slots to device
export const platformVisibility = {
  desktop: [
    PDP_WIDE_BOTTOM,
    PDP_WIDE_BOTTOM_LEFT,
    PDP_WIDE_BOTTOM_RIGHT,
    PDP_WIDE_TOP,
    SRP_WIDE_BOTTOM,
    SRP_WIDE_BOTTOM_LEFT,
    SRP_WIDE_BOTTOM_RIGHT,
    SRP_WIDE_TOP,
    PDP_WIDE_MID,
    SRP_WIDE_MID,
    HOME_WIDE_MID,
    HOME_WIDE_BOTTOM,
    LAND_WIDE_BOTTOM,
    LAND_WIDE_TOP,
    TEST
  ],
  mobile: [
    PDP_NARROW_MID,
    PDP_NARROW_TOP,
    SRP_NARROW_MID,
    SRP_NARROW_TOP,
    PDP_NARROW_BOTTOM,
    PDP_NARROW_BOTTOM_TOP,
    SRP_NARROW_BOTTOM,
    SRP_NARROW_BOTTOM_TOP,
    HOME_NARROW_MID,
    LAND_NARROW_TOP,
    HOME_NARROW_BOTTOM,
    LAND_NARROW_BOTTOM,
    PDP_STICKY_BOTTOM,
    SRP_STICKY_BOTTOM
  ]
};

export const defineSlotData = (
  { slot, dimensions, networkId }: { slot: string; dimensions: Dimensions; networkId?: string },
  cb: (({ slotName }: { slotName: string }) => void) | null,
  win: Window = window
): void => {
  const { googletag } = win;

  const slotName = `/${networkId}/${slot}`;

  googletag.cmd.push(() => {
    googletag.defineSlot(slotName, [dimensions], slot)?.addService(googletag.pubads());
  });

  if (cb) {
    cb({ slotName });
  }
};

export const initializeAps = (
  { sourceId, networkId, slots }: { sourceId?: string; networkId?: string; slots: { name: string; dimensions: Dimensions }[] },
  win: Window = window
): void => {
  const { apstag, googletag } = win;
  const { hasApstagAdsToken } = marketplace;

  slots.forEach(({ name: slot, dimensions }) => {
    defineSlotData({ slot, dimensions, networkId }, null, win);
  });

  googletag.cmd.push(() => {
    googletag.pubads().disableInitialLoad();
    /** @todo check why are we using this method that seems to not exist in googletag.PubAdsService type */
    // @ts-ignore
    googletag.pubads().setRequestNonPersonalizedAds(0), // set to false permanent since ads opt out clean up in #18504
      googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();
  });

  apstag.init({
    pubID: sourceId,
    adServer: 'googletag',
    bidTimeout: 2e3
  });

  injectScriptToHead({
    src: GPT_URL
  });

  injectScriptToHead({
    src: APS_URL,
    id: 'apsScript',
    onload: () => {
      fetchBids({ slots, networkId });
    }
  });

  if (hasApstagAdsToken) {
    injectLinkToHead(AD_URL, 'dns-prefetch');
    injectLinkToHead(AD_URL, 'preconnect', true);
  }
};

export const fetchBids = (
  { slots, networkId }: { slots: { name: string; dimensions: Dimensions }[]; networkId?: string },
  win: Window = window
): void => {
  const { apstag, googletag } = win;

  const slotData: { slotID: string; slotName: string; sizes: Dimensions[] }[] = [];

  slots.forEach(({ name: slot, dimensions }) => {
    defineSlotData(
      { slot, networkId, dimensions },
      ({ slotName }) => {
        slotData.push({
          slotID: slot,
          slotName,
          sizes: [dimensions]
        });
      },
      win
    );
  });

  apstag.fetchBids({ slots: slotData }, () => {
    // set apstag bids, then trigger the first request to DFP
    googletag.cmd.push(() => {
      apstag.setDisplayBids();
      googletag.pubads().refresh();
    });
  });

  slots.forEach(({ name: slot }) => {
    googletag.cmd.push(() => {
      googletag.display(slot);
    });
  });
};

export const fireApsAds = (
  slots: {
    name: string;
    device?: string;
    height?: string;
    width?: string;
  }[]
) => {
  const { ads: { networkId, sourceId } = {} } = marketplace;
  const slotsList = formatSlotsList(slots);

  if (!slotsList.length) {
    return;
  }

  try {
    // if script has already been injected, re-fire ad initialization
    if (document.getElementById('apsScript')) {
      fetchBids({ networkId, slots: slotsList });
    } else {
      initializeAps({ networkId, sourceId, slots: slotsList }); // otherwise inject scripts and fire ad initialization
    }
  } catch (e) {
    /* dont blow up if third party script fails */
  }
};

// slots is a list of objects shaped like [ {name: 'HOME-NARROW-MID', size: 'mobile', height: '50', width: '320' } ]. It must at least have the `name` key/value.
export const formatSlotsList = (
  slots: {
    name: string;
    device?: string;
    height?: string;
    width?: string;
  }[] = []
): { name: string; dimensions: Dimensions }[] => {
  const deviceType = isDesktop() ? 'desktop' : 'mobile';
  return slots.reduce(
    (acc, { name, device, height, width }) => {
      if (device === deviceType || platformVisibility[deviceType]?.includes(name)) {
        const widthInt = parseInt(width || '0') || 0;
        const heightInt = parseInt(height || '0') || 0;
        const dimensions = widthInt && heightInt ? [widthInt, heightInt] : getAdDimensions(name);
        acc.push({ name, dimensions: dimensions as Dimensions });
      }
      return acc;
    },
    [] as { name: string; dimensions: Dimensions }[]
  );
};

// todo
/* This function is added to remove the cookies that was generated to personalise the apstag ads on 6pm site. Currently the functionality is
turned off but the cookies are not yet removed. They will automatically get removed after their lifetime, i.e. 14 days from turn off - we
can remove this function after 9th Feb 2023. We are adding this function to make sure that users are not getting personalised content when
personalisation is turned off from our side. */
export const apstagRemoveToken = () => {
  const { apstag } = window;
  if (apstag.dpa) {
    apstag.dpa();
  }
};

export const apstagUpdateToken = (
  customerId: string | null | undefined,
  adCustomerId: string | null,
  adEmailHash: string,
  fetchAccountInfo: () => Promise<{ email?: unknown }>,
  updateAdData: (customerId: string | null, adEmailHash: string | null) => void,
  fetchCustomerAuthDetailsStatus: () => Promise<{ success: boolean }>
): void => {
  const { apstag } = window;
  const tokenConfig = {
    hashedRecords: [
      {
        type: 'email',
        record: adEmailHash
      }
    ]
  };
  if (!customerId) {
    updateAdData(null, null);
    if (apstag.dpa) {
      // dpa is not present on the page refresh & we don't need to update token
      apstag.dpa();
    }
  } else if (customerId !== adCustomerId) {
    fetchCustomerAuthDetailsStatus().then(response => {
      const { success } = response;
      if (success) {
        fetchAccountInfo().then(customerInfo => {
          if (customerInfo) {
            const updatedAdEmailHash = emailHasher(customerInfo.email) as string;
            updateAdData(customerId, updatedAdEmailHash);
            if (tokenConfig.hashedRecords[0]) {
              tokenConfig.hashedRecords[0].record = updatedAdEmailHash;
            }
            if (apstag.rpa) {
              apstag.rpa(tokenConfig);
            }
          }
        });
      }
    });
  } else {
    if (apstag.rpa) {
      apstag.rpa(tokenConfig);
    }
  }
};
