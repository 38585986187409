import { useMemo, useState } from 'react';

import Ad from 'components/landing/Ad';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/hf/6pm/footerAds.scss';

// This will have 2 rows. Row 1 has 3 cols. Row 2 has 1 col going the full width.
// See my shitty drawing: https://github01.zappos.net/storage/user/388/files/248e2a00-a03c-11ea-834f-69b58d2facec
// Symphony Component this is associated with https://symphony.amazon.com/creatives/f71d2174-a270-4d04-ac50-261a1b547b28

const filteredAdsInit = {
  topLeft: [],
  topCenter: [],
  topRight: [],
  bottom: [],
  sticky: []
};
const adPositions = {
  'top-left': 'topLeft',
  'top-center': 'topCenter',
  'top-right': 'topRight',
  'bottom': 'bottom',
  'sticky': 'sticky'
};

export const FooterAds = ({ ad = {} }) => {
  const { componentName, ad: ads = [] } = ad;
  const { testId } = useMartyContext();

  const [stickyAdOpen, setStickyAdOpen] = useState(true);

  const { topLeft, topCenter, topRight, bottom, sticky } = useMemo(
    () =>
      ads.reduce((acc, ad) => {
        const mappedValue = adPositions[ad.position];
        return mappedValue ? { ...acc, [mappedValue]: [...acc[mappedValue], ad] } : acc;
      }, filteredAdsInit),
    [ads]
  );

  if (componentName !== 'ad') {
    return null;
  }

  return (
    <>
      <div className={css.container} data-test-id={testId('footerAdContainer')}>
        <div className={css.topLeft}>
          <Ad containerClassOverride={css.adContainerOverride} ads={topLeft} />
        </div>
        <div className={css.topCenter}>
          <Ad containerClassOverride={css.adContainerOverride} ads={topCenter} />
        </div>
        <div className={css.topRight}>
          <Ad containerClassOverride={css.adContainerOverride} ads={topRight} />
        </div>
        <div className={css.bottom}>
          <Ad containerClassOverride={css.adContainerOverride} ads={bottom} />
        </div>
      </div>
      {/* Sticky Ad is outside the container in its own */}
      {stickyAdOpen && (
        <div className={css.stickyContainer}>
          <Ad containerClassOverride={css.stickyAdContainerOverride} ads={sticky} />
          <button className={css.stickyCloseBtn} type="button" aria-label="Close advertisement" onClick={() => setStickyAdOpen(false)} />
        </div>
      )}
    </>
  );
};

export default withErrorBoundary('FooterAds', FooterAds);
